import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ProductPage from './pages/ProductPage';
import OrderPage from './pages/OrderPage';
import ArmeniaProductPage from './armeniaPages/ArmeniaProductPage';
import ArmeniaOrderPage from './armeniaPages/ArmeniaOrderPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/ru/">
          <ProductPage />
        </Route>
        <Route exact path="/ru/order">
          <OrderPage />
        </Route>
        <Route exact path="/am/">
          <ArmeniaProductPage />
        </Route>
        <Route exact path="/am/order">
          <ArmeniaOrderPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
