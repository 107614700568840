import axios from 'axios';
import { useEffect, useState } from 'react';

export default function ArmeniaOrderPage() {
  const getQueryParamValue = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  };

  const [isLoading, setLoading] = useState(true);
  const [isGood, setGood] = useState(false);

  useEffect(() => {
    handlePayment();
  }, []);

  const handlePayment = async () => {
    const paymentData = {
      order_id: getQueryParamValue('orderID'),
      payment_id: getQueryParamValue('paymentID'),
    };
    const request = await axios.post('https://products.simplesmm.org/api/payments/done', paymentData);
    const { ok } = request.data;
    setGood(ok);
    setLoading(false);
  };
  return (
    <div className="container">
      <div className="header">
        <div className="header-bg">
          <div className="header-content">
          <span>Доступными словами про ведение соцсетей</span>
            <h1>
              Simple SMM
              <br />
              {isLoading ? 'Загрузка' : isGood ? 'Успешная транзакция' : 'Ошибка'}
            </h1>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="label">
          {isLoading
            ? 'Загрузка'
            : isGood
            ? 'Спасибо за ваш заказ! Мы уже отправили вам письмо с подробной информацией, на указанную почту. Если письма нет - проверьте спам.'
            : 'Платеж не был совершен'}
        </div>
      </div>
    </div>
  );
}
